import {
  StyledImg,
  StyledWrapper,
} from '../../../components/SlideItem/Styled.components';
import IMG_1 from '../../../assets/images/merch/Tour Tee.png';
import IMG_2 from '../../../assets/images/merch/Star Belle Tote.png';

const data = [
  {
    img: IMG_1,
    txt: 'Tour Tee',
  },
  {
    img: IMG_2,
    txt: 'Star Belle Tote',
  },
];

export default function Merch() {
  const slideItem = data.map((item) => (
    <div>
      <StyledWrapper className="rounded-primary relative overflow-hidden z-0">
        <img
          src={item.img}
          alt={item.txt}
          className="z-0 w-full object-contain h-[24vh] md:h-[40vh]"
        />
      </StyledWrapper>
      <div className="p-[2vw]">
        <h4 className="text big--size leading-[100%] whitespace-nowrap text-ellipsis w-full block overflow-hidden text-shadow">
          {item.txt}
        </h4>
      </div>
    </div>
  ));

  return (
    <div
      id="merch"
      className="pt-[5vh] md:min-h-screen w-5/6 md:w-3/4 mx-auto flex flex-col justify-center"
    >
      <div className="w-full flex flex-row justify-center items-center gap-[2vw]">
        {slideItem}
      </div>

      <div className="text-center my-[2vw]">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://zyah-belle.myshopify.com/"
          className="btn btn--light"
        >
          View Products
        </a>
      </div>

      <div className="heading heading--2">
        <h2 className="heading--text">merch</h2>
      </div>
    </div>
  );
}
