import HERO from '../../assets/images/bg.desktop.png';
import HERO_MOBILE from '../../assets/images/bg.mobile.png';
import { Carousel } from '../../components';
import IntroLoopBG from '../../components/IntroLoopBG/IntroLoopBG';
import Subscribe from '../../components/Subscribe/Subscribe';
import About from '../About/About';
import Tour from '../Shows/Shows';
import Merch from './Merch/Merch';
import Music from './Music/Music';
import Videos from './Videos/Videos';
import COLORS_IMG from 'file:///home/notkev1n/Downloads/Zyah%20Belle-20240916T224231Z-001/Zyah%20Belle/PNG/Desktop%20A.png';

export default function Home() {
  return (
    <div>
      <div id="home" className="relative h-screen w-full overflow-hidden">
        <IntroLoopBG />
      </div>

      {/* <div>
        <img src={COLORS_IMG} alt="colors" />
      </div> */}
      <Music />
      <Videos />
      <Merch />
      <About />
      <Tour />
      <Subscribe />
      {/* <Merch /> */}
    </div>
  );
}
